import React from 'react'
import { Link } from 'gatsby'

import * as cls from './breadcrumbs.module.scss'

const Breadcrumbs = ({ items }) => {
  const breadcrumbsList = items.map((item, index) => {
    return (
      <li
        key={index}
        className={cls.breadcrumb}
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
      >
        {
          item.url
            ? <Link
              className={cls.breadcrumb__link}
              to={item.url}
              itemProp="item"
            >
              <span itemProp="name">{item.title}</span>
            </Link>
            : <span itemProp="name">{item.title}</span>
        }
        <meta itemProp="position" content={index + 2} />
      </li>
    )
  })

  return (
    <ul
      className={cls.breadcrumbs}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
    >
      <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
        <Link
          to="/"
          className={cls.breadcrumb__link}
          itemProp="item">
          <span itemProp="name">Home</span>
        </Link>
        <meta itemProp="position" content="1"/>
      </li>
      {breadcrumbsList}
    </ul>
  )
}

export default Breadcrumbs
