/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import SiteContext from '~/helpers/siteContext'
import useLanguage from 'components/language/useLanguage'

const Seo = ({ meta, title }) => {
  const { language } = useLanguage()
  const siteData = useContext(SiteContext)
  const seoData = (meta ?? siteData?.seo) || {}
  const metaDescription = seoData.metaDescription
  const metaTitle = seoData.metaTitle
    ? seoData.metaTitle
      ? seoData.metaTitle
      : title
    : siteData?.title

  const metaLinks = []
  if (Array.isArray(seoData.alternates)) {
    seoData.alternates.forEach((alternate) => {
      metaLinks.push({
        rel: 'alternate',
        hreflang: alternate.language,
        href: alternate.path,
      })
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={metaTitle}
      link={metaLinks}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: seoData?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      {
        seoData?.structuredData
          ? <script type="application/ld+json">{JSON.stringify(seoData?.structuredData)}</script>
          : null
      }
    </Helmet>
  )
}

Seo.defaultProps = {
  meta: {},
}

Seo.propTypes = {
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
}

export default Seo
