import React from 'react'
import { LanguageContext } from 'components/language/languageContext'
import config from './config'

const useLanguage = () => {
  const { language } = React.useContext(LanguageContext)

  return {
    ...config,
    language,
  }
}

export default useLanguage
